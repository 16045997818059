export const TOP_BAR_HEIGHT = 46;
export const TRIAL_BANNER_HEIGHT = 38;
export const DATA_NAV_BAR_HEIGHT = 46;
export const ACTIVE_FILTERS_ROW_HEIGHT = 46;
export const APP_FOOTER_HEIGHT = 80;
/** Left navigation panel */
export const NAV_BAR_WIDTH = 200;
export const NAV_BAR_WIDTH_SMALL = 49;
export const NAV_BAR_FOOTER_HEIGHT = 85;

export const SEARCHES_MODALS_WIDTH = 310;
export const LOGOUT_MODAL_WIDTH = 258;
export const BOOK_CALL_MODAL_WIDTH = 364;
export const IN_PAGE_NAV_WIDTH = 150;

/** Setting used in Delas, Companies, Investors pages */
export const FILTER_PANEL_WIDTH = 300;
/** Pagination settings */
export const DEFAULT_ITEMS_PER_PAGE = 50;
export const DEFAULT_ITEMS_PER_PAGE_DETAILS = 10;
export const DEFAULT_ITEMS_PER_PAGE_SIGHTLINES = 36;
export const HOME_ITEMS_PER_PAGE = 3;
export const HOME_ITEMS_PER_PAGE_MONITOR = 4;
export const ITEMS_PER_PAGE_OPTIONS = [DEFAULT_ITEMS_PER_PAGE.toString(), "75", "100"];
/** Investment dash settings */
export const INVESTMENT_DASH_MARKET_MAP_MAX_COMPANIES = 300;
export const INVESTMENT_DASH_TOP_CLIMATE_DEALS_MAX_INVESTORS = 10;
export const INVESTMENT_DASH_LEADERBOARD_MAX_INVESTORS = 20;
//** Sector Compass */
export const USER_TIER_BANNER_WIDTH = 930;
export const DEPLOYMENT_DEFAULT_FILTERS = { project_facility_type: "INSTALLATION" };

/** Global Search settings */
export const GLOBAL_SEARCH_ITEMS_PER_PAGE = 10;

/** Transition duration used in theme config in `ms` */
export const DEFAULT_TRANSITION_DURATION = 300;
/** Notfication close timeout */
export const NOTIFICATION_CLOSE_TIME = 5 * 1000;
/** Filter panels transition time `ms` */
export const FILTER_GROUP_TRANSITION_DURATION = 200;
/** Debounce time for filter values set by user typing action */
export const FILTER_INPUT_DEBOUNCE = 300;
export const FILTER_MIN_TEXT_SEARCH = 2;
export const DROPDOWN_MAX_HEIGHT = 430;

/** Formats used for dates display */
export const DATE_FORMAT = "DD MMM YYYY";
export const DATE_FORMAT_FULL_YEAR = "YYYY";
export const DATE_FORMAT_MONTH_AND_YEAR = "MMM YYYY";
export const DATE_PICKER_FORMAT = "DD-MM-YY";
export const DATE_PUBLISHED_AT_FORMAT = "DD MMMM YYYY";

export const TECH_PROFILE_LIST_ROW_HEIGHT = 64;
export const TECH_PROFILE_STICKY_HEADER_HEIGHT = 68;
export const TECH_PROFILE_SCROLL_BUFFER = 30;

export enum OrganizationDetailsTabs {
  sightlines = "sightlines",
  featured = "featured",
  snapshot = "snapshot",
  subsidiaries = "subsidiaries",
  investors = "investors",
  deals = "deals",
  projects = "projects",
  portfolio = "portfolio",
  investments = "investments",
  similarCompanies = "similarCompanies",
  funds = "funds",
}

export enum ProjectDetailsTabs {
  progress = "progress",
  commentary = "commentary",
  readiness = "readiness",
  participants = "participants",
  location = "location",
  timeline = "timeline",
}

/** Used to generate id from list of ids */
export const DATA_JOINER = "_";

export const Z_INDEX_MAP = {
  overMenuDropdown: 301,
  header: 100,
  leftNavBar: 100,
  capitalStackNav: 99,
  userSettingsModal: 99,
  loader: 99,
  globalSearchModal: 98,
  stickyBreadcrumbs: 97,
  banner: 96,
  filterDrawer: 10,
  onboarding: 9,
  raceToScale: {
    header: 3,
    dot: 1,
    dotHover: 2,
  },
  // Reminder: Add all z-index values here
};

export const PASSWORD_REGEXES = {
  numChars: /^.{8,}$/,
  uppercase: /[A-Z]/,
  lowercase: /[a-z]/,
  number: /[0-9]/,
  symbol: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/,
  password: /^(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/,
};

export const EMAIL_REGEX = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

/**
 * The below ids are used for downloading screenshots from the application
 * They idenrify the elements passed to html2canvas handler
 */
export const DOWNLOAD_IDS = {
  stageChartId: "deals-by-stage", // Investment dashboard, Sector compass
  sectorChartId: "deals-by-sector", // Investment dashboard, Sector compass
  contextId: "sector-compass-context", // Sector compass
  marketMapId: "sector-compass-marker-map", // Sector compass
  projectsProgressId: "sector-compass-projects-progress", // Sector compass
  projectsScaleId: "sector-compass-projects-scale", // Sector compass
  projectsTechnologyId: "sector-compass-projects-technology", // Sector compass
  projectsCountryId: "sector-compass-projects-country", // Sector compass
  projectsOperationalDateId: "sector-compass-operational-date-pipeline", // Sector compass
  projectsRaceToScaleId: "sector-compass-race-to-scale", // Sector compass
  companiesRaceToScaleId: "sector-compass-race-to-scale-company", // Sector compass
  organizationSnapshotStageId: "organization-snapshot-stage", // Organization profile
  organizationSnapshotSectorId: "organization-snapshot-sector", // Organization profile
  capitalStackId: "investor-snapshot-capital-stack", // Investment dashboard, Capital stack
  marimekkoChartId: "climate-investment-by-taxonomy", //Investment dashboard
  annualStageChartId: "annual-deals-by-stage", // Investment dashboard
  annualSectorChartId: "annual-deals-by-sector", // Investment dashboard
  investorsLeaderboardId: "investors-leaderboard", //Investment dashboard
  topClimateDealsChartId: "top-climate-deals", //Investment dashboard
  exitsChartId: "exits", //Investment dashboard
};

/**
 * In case we need to ignore an element(s) that sits under a html chunk we need to screenshot,
 * use the below class name to mark it(them)
 * `downloadHtml` is hanldling it under the hood
 */
export const DOWNLOAD_IGNORE_CLASS = "remove-from-download";
export const DOWNLOAD_REVEAL_SCROLL_CONTENT_ATTR = "data-remove-scroll-container";
export const DOWNLOAD_REMOVE_SCROLL_PROP = { [DOWNLOAD_REVEAL_SCROLL_CONTENT_ATTR]: true };

export const MODAL_IDS = {
  saveSearch: "save-search",
  editSearch: "edit-search",
  deleteSearch: "delete-search",
  openSearchGuide: "open-search-guide",
  openSearchGuideInvestmentDash: "open-search-guide-investment-dash",
  capitalStackGuide: "open-capital-stack-guide",
};
