import { User } from "firebase/auth";

import { type BERecordToUI, type UnionToTuple } from "./utils";

export type SignInParams = {
  email: string;
  password: string;
};

export enum AccessLevelEnum {
  INTERNAL = "INTERNAL",
  TRIAL = "TRIAL",
  MONITOR = "MONITOR",
  DEPLOY = "DEPLOY",
  DEPLOY_PLUS = "DEPLOY_PLUS",
  LEGACY_CORE = "LEGACY_CORE",
  LEGACY_PRO = "LEGACY_PRO",
}

export type AccessLevels = "INTERNAL" | "TRIAL" | "MONITOR" | "DEPLOY" | "DEPLOY_PLUS" | "LEGACY_CORE" | "LEGACY_PRO";
export type TestPhases = "ALPHA" | "BETA";
// Convert the union type to a tuple
export type AccessLevelsTuple = UnionToTuple<AccessLevels>;
export type TestPhasesTuple = UnionToTuple<TestPhases | undefined>;

export type UserBE = {
  id: number;
  email: string;
  first_name: string;
  last_name: string;
  access_level: AccessLevels;
  test_phase?: TestPhases;
  has_subscriptions: boolean;
  organization_id: number;
  download_limit: number;
  remaining_xlsx_download_rows: number;
  remaining_trial_days: number;
  sectors: number[];
};

type FBUserFieldsWeUse = Pick<User, "uid" | "metadata">;
export type UserBEMergedWithFBData = Nullable<UserBE & FBUserFieldsWeUse>;

export type UserFE = Nullable<
  FBUserFieldsWeUse &
    BERecordToUI<Omit<UserBE, "id">> & {
      localId: string;
      fullName: string;
      organizationId: number;
    }
>;

export type SignupParamsFE = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  organisation: string;
  department: string;
  jobTitle: string;
  cityId: string;
  cityName: string;
  password: string;
  confirmPassword: string;
  token?: string;
};

export type AccountInfoParamsFE = Pick<
  SignupParamsFE,
  "id" | "firstName" | "lastName" | "email" | "organisation" | "department" | "jobTitle" | "cityId" | "cityName"
>;

export type ChangePassewordParamsFE = Pick<SignupParamsFE, "id" | "password" | "confirmPassword"> & {
  currentPassword: string;
};

export type UserProfilePayload = {
  first_name: string;
  last_name: string;
  department: string;
  title: string;
  city_id?: string;
};

export type UserProfileBE = {
  id: number;
  username: string;
  first_name: string;
  last_name: string;
  email: string;
  is_staff: boolean;
  password_updated_at: string;
  last_login: string;
  organization_id: 0;
  organization_name: string;
  department: string;
  title: string;
  city_id: string;
  city_name: string;
};

export type UserSignupInfoBE = UserBE &
  MakeNullable<
    {
      id: number;
      token: string;
      organization_name: string;
      department: string;
      title: string;
      city_id: string;
      city_name: string;
    },
    "id" | "token"
  >;
