import { FC } from "react";

import { Menu } from "@mantine/core";
import { useTranslation } from "react-i18next";

import Dropdown from "@Components/Dropdown/Dropdown";
import { withUserEntitlementAccess } from "@Components/user/withUserEntitlementAccess";
import { DOWNLOAD_IDS } from "@Lib/constants/ui";
import { useDisableXLSXDownload, useDownloadHTMLChunk } from "@Lib/hooks/common";
import { useSectorChartDownload, useStageChartDownload } from "@Lib/hooks/deals";
import {
  useInvestmentChartsStore,
  useChartsFilterStore,
  filterForAPISelector,
} from "@Lib/store/sectorCompass/investment";

type DownloadButtonProps = {
  sectorId: number;
  marketName: string;
  disabled?: boolean;
};

const DownloadButton: FC<DownloadButtonProps> = ({ sectorId, marketName, disabled }) => {
  const { t } = useTranslation();
  const { isDisabled } = useDisableXLSXDownload();

  const datasetType = useInvestmentChartsStore(state => state.datasetType);
  const storeFilters = useChartsFilterStore(filterForAPISelector);

  const mutationStage = useStageChartDownload({
    datasetType,
    primarySectorId: sectorId,
    storeFilters,
  });

  const mutationSector = useSectorChartDownload({
    datasetType,
    primarySectorId: sectorId,
    storeFilters,
  });

  const { handleDownload: handleStageDownload, isLoading: isStageChartLoading } = useDownloadHTMLChunk({
    elementId: DOWNLOAD_IDS.stageChartId,
    fileName: `${marketName} (deals stage chart)`,
    branded: true,
  });
  const { handleDownload: handleSectorDownload, isLoading: isSectorChartLoading } = useDownloadHTMLChunk({
    elementId: DOWNLOAD_IDS.sectorChartId,
    fileName: `${marketName} (deals sector chart)`,
    branded: true,
  });
  const handleStageDownloadCSV = () => mutationStage.mutate();
  const handleSectorDownloadCSV = () => mutationSector.mutate();

  const isLoading = isStageChartLoading || isSectorChartLoading || mutationSector.isLoading || mutationStage.isLoading;

  return (
    <Dropdown btnText={t("download.btnText")} position="bottom-end" isLoading={isLoading} isDisabled={disabled}>
      <Menu.Item onClick={handleStageDownload}>
        {t("download.fileNames.sectorCompass.stageChart", {
          type: "jpg",
        })}
      </Menu.Item>
      <Menu.Item onClick={handleSectorDownload}>
        {t("download.fileNames.sectorCompass.sectorChart", {
          type: "jpg",
        })}
      </Menu.Item>
      <Menu.Item onClick={handleStageDownloadCSV} disabled={isDisabled}>
        {t("download.fileNames.sectorCompass.stageChart", {
          type: "xlsx",
        })}
      </Menu.Item>
      <Menu.Item onClick={handleSectorDownloadCSV} disabled={isDisabled}>
        {t("download.fileNames.sectorCompass.sectorChart", {
          type: "xlsx",
        })}
      </Menu.Item>
    </Dropdown>
  );
};

const TieredDownloadButton = withUserEntitlementAccess(DownloadButton, "downloads", { enforceAccessCheck: false });

export default TieredDownloadButton;
