import { useEffect, useMemo, useState, useRef, FC, PropsWithChildren } from "react";

import { User } from "firebase/auth";
import { useNavigate, useLocation } from "react-router-dom";

import { firebaseAuth } from "@Lib/config/firebase";
import { ROUTES, PUBLIC_LANDING_ROUTES } from "@Lib/constants";
import { useFetchMe, useGetAndSetServerTime } from "@Lib/hooks/user";
import { type ErrorCodes } from "@Lib/types/error";

import { AuthContext } from "./AuthContext";
import { useHanldeAuthRouting, useClearOnLogout } from "./hooks";

const AuthProvider: FC<PropsWithChildren> = ({ children }) => {
  const navigate = useNavigate();
  const location = useLocation();

  const fbAuthCheckedRef = useRef(false);
  const [firebaseUser, setFirebaseUser] = useState<Nullable<User>>(null);
  const [loginErrorCode, setLoginErrorCode] = useState<Nullable<ErrorCodes>>(null);

  const { data: timestamp } = useGetAndSetServerTime();
  // In case we are at ROUTES.signup we don't fetch data from useFetchMe
  // `localUser` controls navigation to protected routes
  const { data: localUser } = useFetchMe(
    PUBLIC_LANDING_ROUTES.has(location.pathname) || !timestamp ? null : firebaseUser,
    setLoginErrorCode
  );

  const clearOnLogout = useClearOnLogout();

  //In case any of the auth logic is changed, both useEffects(here and in '/UserTrackingProvider') need to be changed accordingly
  useEffect(() => {
    const unsubscribe = firebaseAuth.onAuthStateChanged(user => {
      setFirebaseUser(user);
      fbAuthCheckedRef.current = true;

      if (PUBLIC_LANDING_ROUTES.has(window.location.pathname)) {
        return;
      }

      if (!user) {
        clearOnLogout();
        // Preserve query parameters by appending them to the login URL
        const loginPath = location.search ? `${ROUTES.login}${location.search}` : ROUTES.login;
        navigate(loginPath, { replace: true });
      }
    });

    return () => {
      unsubscribe();
    };
  }, [location.search]);

  useHanldeAuthRouting(localUser, fbAuthCheckedRef.current);

  const contextValue = useMemo(() => {
    if (!firebaseUser || !localUser) {
      return {
        user: null,
        loginErrorCode,
      };
    }

    return {
      user: localUser,
      loginErrorCode,
    };
    // We need to track `firebaseUser` change to clear context value in case
    // user is signout from `onAuthStateChanged`
  }, [firebaseUser, localUser, loginErrorCode]);

  return <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
