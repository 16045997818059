export const USER_TRACKING_EVENTS = {
  login: "Login",
  logout: "Logout",
  pageView: "Page view",
  facets: "Filters applied",
  search: "Search term used",
  download: "Download",
  downloadError: "Download failed",
  appState: "App state",
  globalSearch: "Global search used result",
  timeSpendOnPage: "Time spent on page",
  scrollSightline: "Commentary scroll",
  exploreUnderlyingData: "Explore data button used",
  notableProjects: "Notable Project viewed",
  sightline: "Sightline viewed",
  sectorCompassSection: "Sector compass section viewed",
};

export const USER_TRACKING_PROPERTIES = {
  filtersMechanism: {
    dataPageFilterPanel: "Data Page Global Filter",
    investmentDashGlobalFilter: "Investment Dash Global Filter",
    investmentDashBarChartClick: "Investment Dash Bar Chart",
    investmentDashMekkoChartClick: "Investment Dash Marimekko Chart",
    sectorCompassDeployment: "Sector Compass Deployment Section",
    sightlinesPageFilterPanel: "Sightlines Page Filter",
  },
  chartName: {
    climateInvestment: "Investment Bar Chart",
    annualClimateInvestment: "Annual Trend Bar Chart",
    investmentByTaxonomy: "Marimekko Chart",
    capitalStack: "Investors Market Map",
    topClimateDeals: "Top Climate Deals",
    exits: "Exits Chart",
    leaderBoard: "Investors Leaderboard",
  },
  navigateMethod: {
    scroll: "Scroll",
    tab: "Clicked tab",
    url: "Direct to URL",
  },
};
