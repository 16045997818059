import { TranslationKeys } from "@Lib/i18n/config";
import { AccessLevels, UserFE } from "@Lib/types/user";

export type Features = "downloads" | "analyst_call";

type FeatureSettingsBasic = {
  tooltipContent: TranslationKeys | null;
};

type FeatureSettingGlobal = FeatureSettingsBasic & {
  isDisabled: boolean;
  disableCondition?: never;
};

type FeatureSettingConditional = FeatureSettingsBasic & {
  isDisabled?: never;
  disableCondition: (user: UserFE) => boolean;
};

type FeatureSettings = FeatureSettingGlobal | FeatureSettingConditional;

type SelectedAccessLevels = Pick<Record<AccessLevels, unknown>, "TRIAL" | "MONITOR" | "DEPLOY">;

type TierConfig = {
  [tier in keyof SelectedAccessLevels]: {
    [feature in Features]?: FeatureSettings;
  };
};

export const tierConfig: TierConfig = {
  TRIAL: {
    downloads: {
      tooltipContent: "download.tierTooltips.trial",
      isDisabled: true,
    },
  },
  MONITOR: {
    downloads: {
      tooltipContent: "download.tierTooltips.limitExceeded",
      disableCondition: user => user?.remainingXlsxDownloadRows === 0,
    },
    analyst_call: {
      tooltipContent: "bookCallButtonText.tierTooltips.monitor",
      isDisabled: true,
    },
  },
  DEPLOY: {
    downloads: {
      tooltipContent: "download.tierTooltips.limitExceeded",
      disableCondition: user => user?.remainingXlsxDownloadRows === 0,
    },
  },
};
