import { forwardRef } from "react";

import { Anchor, Box, BoxProps, Center, createStyles, ScrollArea } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";

import InfoIcon from "@Assets/investment-menu-methodology.svg?react";
import AccessLevelSwitch from "@Components/AccessLevelSwitch";
import Banner from "@Components/Banner/Banner";
import Card from "@Components/Cards/Card";
import NotableChipRow from "@Components/Cards/Card/NotableChipRow";
import ErrorBox from "@Components/ErrorBox/ErrorBox";
import { GoToLink, LinkRow } from "@Components/GoToLink";
import InfoButton from "@Components/InfoButton";
import PageSection from "@Components/PageSection/PageSection";
import { DATE_FORMAT } from "@Lib/constants";
import { useGetNotableProjectsBySector } from "@Lib/hooks/sectorCompass";
import { useFiltersStore } from "@Lib/store/filters/data";
import { getFormattedDate } from "@Lib/utils/date";
import { getSupportMailToHref } from "@Lib/utils/formatters";
import { generateLinkUrl } from "@Lib/utils/routes";

const useStyles = createStyles(theme => ({
  content: {
    height: "100%",
    paddingTop: theme.spacing.sm,
    marginInline: theme.spacing.sm,
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(3, 1fr)",
    gap: theme.spacing.sm,
    [theme.fn.smallerThan("lg")]: {
      gridTemplateColumns: "repeat(2, 1fr)",
    },
    [theme.fn.smallerThan("sm")]: {
      gridTemplateColumns: "1fr",
    },
  },
  monitorBanner: {
    margin: theme.spacing.sm,
  },
}));

type NotableProjectsSectionProps = BoxProps & {
  sectorId: number;
  sectorPath: string[];
  isShallow: boolean;
};

const TrialBanner = () => {
  const { t } = useTranslation();
  return <Banner text={t("sectorCompassPage.sections.notable.banners.trial")} mb="sm" icon={<InfoIcon />} />;
};

type ProjectsListProps = Pick<NotableProjectsSectionProps, "sectorId" | "sectorPath">;

const ProjectsList = ({ sectorId, sectorPath }: ProjectsListProps) => {
  const { data, isLoading, isError } = useGetNotableProjectsBySector(sectorId);
  const { t } = useTranslation();
  const { classes } = useStyles();
  const goto = generateLinkUrl("projects");

  const setTaxonomyFilter = useFiltersStore(state => state.setTaxonomyFilter);
  const clearFilters = useFiltersStore(state => state.clearFilters);

  const handleLinkClick = () => {
    clearFilters();
    setTaxonomyFilter({
      action: "add",
      filterField: "sector_id",
      localField: "companySectorIdsLocal",
      value: sectorPath.join("_"),
    });
  };

  if (isLoading) return <Center className={classes.content}>{t("sectorCompassPage.sections.notable.loading")}</Center>;

  if (isError) return <ErrorBox text={t("sectorCompassPage.sections.notable.errorText")} />;

  return (
    <>
      <ScrollArea className={classes.content}>
        <AccessLevelSwitch defaultComponent={""} TRIAL={<TrialBanner />} />
        <Box className={classes.grid}>
          {data?.map(({ id, name, companies, revisedDate, notableStatusName, is_available_in_trial }) => {
            const { name: companyName, logo } = companies[0];
            const date = getFormattedDate(revisedDate, DATE_FORMAT);

            const commonCardProps = {
              chips: <NotableChipRow status={notableStatusName} />,
              imgSrc: logo,
              title: name,
              subtitle: companyName,
              date: t("sectorCompassPage.sections.notable.card.revisedDate", { date }),
              linkText: t("sectorCompassPage.sections.notable.card.link"),
              goto: generateLinkUrl("projectById", id),
            };

            const defaultCard = <Card {...commonCardProps} />;

            const trialCard = (
              <Card
                {...commonCardProps}
                {...(!is_available_in_trial
                  ? {
                      linkText: undefined,
                      goto: undefined,
                    }
                  : {})}
              />
            );

            return <AccessLevelSwitch key={id} defaultComponent={defaultCard} TRIAL={trialCard} />;
          })}
        </Box>
      </ScrollArea>
      <Box onClick={handleLinkClick}>
        <LinkRow>
          <GoToLink to={goto} text={t("sectorCompassPage.sections.notable.link")} />
        </LinkRow>
      </Box>
    </>
  );
};

const NotableProjectsSection = forwardRef<HTMLDivElement, NotableProjectsSectionProps>(
  ({ sectorId, sectorPath, isShallow, ...boxProps }, ref) => {
    const { t } = useTranslation();
    const { classes } = useStyles();

    const monitorBanner = (
      <Banner
        className={classes.monitorBanner}
        text={<Trans i18nKey="sectorCompassPage.sections.notable.banners.monitor" />}
        mb="sm"
        icon={<InfoIcon />}
      />
    );

    const deployBanner = (
      <Banner
        className={classes.monitorBanner}
        text={<Trans i18nKey="sectorCompassPage.sections.notable.banners.deploy" />}
        mb="sm"
        icon={<InfoIcon />}
      />
    );

    return (
      <PageSection
        ref={ref}
        {...boxProps}
        title={t("sectorCompassPage.sections.notable.title")}
        infoButton={
          <InfoButton
            withinPortal
            position="bottom-start"
            contentTitle={t("sectorCompassPage.sections.deployment.notableInfoTitle")}
            content={
              <Trans
                i18nKey={"sectorCompassPage.sections.deployment.notableInfoContent"}
                components={{
                  a: <Anchor href={getSupportMailToHref()} />,
                }}
              />
            }
          />
        }
      >
        <AccessLevelSwitch
          defaultComponent={<ProjectsList sectorId={sectorId} sectorPath={sectorPath} />}
          MONITOR={monitorBanner}
          DEPLOY={isShallow ? deployBanner : <ProjectsList sectorId={sectorId} sectorPath={sectorPath} />}
        />
      </PageSection>
    );
  }
);
export default NotableProjectsSection;
