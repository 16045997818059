import { forwardRef } from "react";

import { BoxProps } from "@mantine/core";
import { Trans, useTranslation } from "react-i18next";

import InfoIcon from "@Assets/investment-menu-methodology.svg?react";
import AccessLevelSwitch from "@Components/AccessLevelSwitch";
import Banner from "@Components/Banner/Banner";
import { GoToLink, LinkRow } from "@Components/GoToLink";
import SightlineSection from "@Components/SightlineSection";
import { useGetSightlinesBySectorId } from "@Lib/hooks/sightlines";
import { generateLinkUrl } from "@Lib/utils/routes";

import SectionHolder from "../SectionHolder";

type SightlineListSection = BoxProps & {
  sectorId: number;
  sectorPath: string[];
  isShallow: boolean;
};

const SightlineListSection = forwardRef<HTMLDivElement, SightlineListSection>(
  ({ sectorId, sectorPath, isShallow, ...boxProps }, ref) => {
    const { t } = useTranslation();

    const query = useGetSightlinesBySectorId(sectorId);
    const gotoParams = new URLSearchParams({ sectorPath: sectorPath.join() });
    const goto = `${generateLinkUrl("sightlines")}?${gotoParams.toString()}`;

    const defaultComponent = (
      <>
        <SightlineSection query={query} />
        <LinkRow>
          <GoToLink to={goto} text={t("sectorCompassPage.sections.sightlines.link")} />
        </LinkRow>
      </>
    );

    const monitorBanner = (
      <Banner
        m={"sm"}
        icon={<InfoIcon />}
        text={<Trans i18nKey="sectorCompassPage.sections.sightlines.banners.monitor" components={{ b: <b /> }} />}
      />
    );

    const deployBanner = (
      <Banner
        m={"sm"}
        icon={<InfoIcon />}
        text={<Trans i18nKey="sectorCompassPage.sections.sightlines.banners.deploy" components={{ b: <b /> }} />}
      />
    );

    return (
      <SectionHolder ref={ref} {...boxProps} title={t("sectorCompassPage.sections.sightlines.title")}>
        <AccessLevelSwitch
          defaultComponent={defaultComponent}
          MONITOR={monitorBanner}
          DEPLOY={isShallow ? deployBanner : defaultComponent}
        />
      </SectionHolder>
    );
  }
);

export default SightlineListSection;
