import { useAuthContext } from "@Context/Auth";
import { tierConfig } from "@Lib/config/userTier";

export const useDisableXLSXDownload = () => {
  const { user } = useAuthContext();

  const userTier = user?.accessLevel as keyof typeof tierConfig | undefined;

  const xlsxDownloadSettings = userTier ? tierConfig[userTier]?.downloads : undefined;
  const isDisabled = xlsxDownloadSettings?.disableCondition
    ? xlsxDownloadSettings.disableCondition(user)
    : xlsxDownloadSettings?.isDisabled;

  return { isDisabled };
};
