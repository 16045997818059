import { SORT_FIELDS_MAP } from "@Lib/constants";
import { type PaginationDataPayload, type RequestDownloadPayload } from "@Lib/types/base";
import { NotableProjectsResponse, type ProjectBE, type ProjectResponse } from "@Lib/types/projects";
import { getTimeZoneHeader, getXLSXDownload } from "@Lib/utils/api";
import { mapSortUItoBE } from "@Lib/utils/queries";

import { goldApi } from "./api";

export const getProjectById = async (projectId: string) => {
  const { data } = await goldApi.get<ProjectBE>(`/projects/${projectId}`);

  return data;
};

export const getAllProjects = async ({ page, perPage, filters, sorting }: PaginationDataPayload) => {
  const sortingParams = mapSortUItoBE(SORT_FIELDS_MAP.projects, sorting);
  const { data } = await goldApi.get<ProjectResponse>("/projects", {
    params: {
      page,
      page_size: perPage,
      ...filters,
      ...sortingParams,
    },
  });

  return data;
};

export const getAllProjectsDownload = async ({ filters, sorting, rowCount }: RequestDownloadPayload) => {
  const { data, headers } = await getXLSXDownload("/projects", {
    ...filters,
    ...mapSortUItoBE(SORT_FIELDS_MAP.projects, sorting),
    row_count: rowCount,
  });

  return { data, headers };
};

export const requestProjectsDownload = async ({ filters, sorting, rowCount }: RequestDownloadPayload) => {
  const { data } = await goldApi.get<{ id: string }>("/projects/download", {
    params: {
      ...filters,
      ...mapSortUItoBE(SORT_FIELDS_MAP.projects, sorting),
      row_count: rowCount,
    },
    headers: getTimeZoneHeader(),
  });

  return data;
};

export const getNotableProjects = async (
  sectorId?: number,
  page?: number,
  perPage?: number,
  isAvailableInTrial?: boolean
): Promise<NotableProjectsResponse> => {
  const { data } = await goldApi.get<NotableProjectsResponse>("/projects/notable", {
    params: {
      project_sector_id: sectorId,
      page,
      page_size: perPage,
      is_available_in_trial: isAvailableInTrial,
    },
  });

  return data;
};
