import { createContext, useContext } from "react";

import { DownloadFileTypesEnum } from "@Lib/constants/api";
import { type NotableTrackingProps } from "@Lib/types/projects";
import { type SectorCompassSectionTrackingProps } from "@Lib/types/sectorCompass";
import { type SightlineTrackingProps } from "@Lib/types/sightlines";

export type UserTrackingContextValues = {
  trackLogout: () => void;
  trackPageView: (properties: { path: string; pageName: string }) => void;
  trackFilters: (properties: { filter: string; value: string | string[]; mechanism: string }) => void;
  trackDownloadError: (properties: { fileName: string; pathname?: string }) => void;
  trackExploreUnderlyingData: (properties: { chartName: string; path: string; filters: string }) => void;
  trackGSUsedResults: (properties: {
    resultType: "company" | "investor" | "taxonomy" | "project" | "funds";
    resultValue: string;
  }) => void;
  getSearchTermTracker: (searchFiled: string) => (properties: { searchTerm: string }) => void;
  trackDownload: (properties: {
    fileName: string;
    fileType?: DownloadFileTypesEnum;
    pathname?: string;
    overrideFileType?: boolean;
  }) => void;
  trackNotableProject: (properties: NotableTrackingProps) => void;
  trackSightline: (properties: SightlineTrackingProps) => void;
  trackSectorCompassSection: (properties: SectorCompassSectionTrackingProps) => void;
};

export const UserTrackingContext = createContext<UserTrackingContextValues>(null!);

UserTrackingContext.displayName = "UserTrackingContext";

export const useUserTrackingContext = () => useContext(UserTrackingContext);
