import { useQuery } from "@tanstack/react-query";

import { useAuthContext } from "@Context/Auth";
import { QUERIES } from "@Lib/constants";
import { getCompassById } from "@Lib/services/sectorCompass";
import { type SectorCompassBE, type SectorCompassUI } from "@Lib/types/sectorCompass";
import { AccessLevelEnum, type UserFE } from "@Lib/types/user";

const selectCompassData = (
  {
    has_insights,
    sector_path_id,
    market_framework,
    projects,
    scales,
    capacity_units,
    has_notable_projects,
    show_deployment_section,
    ...compass
  }: SectorCompassBE,
  user: UserFE
): SectorCompassUI => ({
  capacityUnits: capacity_units,
  frameworkSrc: market_framework,
  hasSightlines: has_insights,
  sectorPath: sector_path_id.map(id => id.toString()),
  hasProjects: projects.has_projects,
  projectsStartDate: projects.operation_date_min,
  projectsEndDate: projects.operation_date_max,
  hasNotableProjects: has_notable_projects,
  showDeploymentSection: show_deployment_section,
  scales: scales.map(({ scale, capacity_range, ...rest }) => ({
    id: scale.id?.toString(),
    name: scale?.name,
    capacityRange: capacity_range,
    ...rest,
  })),
  isShallow: Boolean(
    user?.accessLevel === AccessLevelEnum.MONITOR ||
      (user?.sectors.length !== 0 && !user?.sectors.includes(compass.sector))
  ),
  ...compass,
});

export const useGetCompassById = (compassId = "") => {
  const { user } = useAuthContext();

  return useQuery({
    queryKey: QUERIES.getCompassById(compassId),
    queryFn: () => getCompassById(compassId),
    enabled: !!compassId,
    staleTime: Infinity,
    select: data => selectCompassData(data, user),
  });
};
