import { ROUTES, PUBLIC_ROUTES } from "@Lib/constants/routes";

type RouteKey = Exclude<keyof typeof ROUTES, "investorById" | "companyById">;

/**
 * Generates a URL by replacing the slug placeholder in the route with the provided slug value.
 *
 * @param {RouteKey} routeKey - The key of the route in the `ROUTES` object.
 * @param {string} [slugValue] - The value to replace the slug placeholder in the route. If not provided, the route will be returned as is.
 * @returns {string} The generated URL with the slug value inserted.
 * @throws {Error} Throws an error if the route key is not found in the `ROUTES` object or if the slug placeholder is not found in the route.
 */
export const generateLinkUrl = (routeKey: RouteKey, slugValue?: string): string => {
  const route = ROUTES[routeKey];
  if (!route) {
    throw new Error(`Route not found for key: ${routeKey}`);
  }

  if (!slugValue) {
    return route;
  }

  if (!route.includes(":")) {
    throw new Error(`Slug placeholder not found in route: ROUTES.${routeKey}="${route}"`);
  }

  return route.replace(/:([^/]+)/, slugValue);
};

// Routing utils
export const isPublicRoute = (route: string) => PUBLIC_ROUTES.has(route);
export const getLandingRedirect = (pathname: string, search?: string) => {
  const path = !isPublicRoute(pathname) ? pathname : ROUTES.home;
  return search ? `${path}${search}` : path;
};
