import { SORT_FIELDS_MAP, CHART_PAST_DATE_LIMIT, DatasetTypesEnum } from "@Lib/constants";
import { type PaginationDataPayload, type RequestDownloadPayload } from "@Lib/types/base";
import {
  type DealsResponse,
  type DealsChartResponse,
  type DatasetTypes,
  type DealsChartServiceInput,
} from "@Lib/types/deals";
import { getTimeZoneHeader, getXLSXDownload } from "@Lib/utils/api";
import { mapSortUItoBE } from "@Lib/utils/queries";

import { goldApi } from "./api";

export const getAllDeals = async ({ page, perPage, filters, sorting }: PaginationDataPayload) => {
  const { data } = await goldApi.get<DealsResponse>("/deals", {
    params: {
      page,
      page_size: perPage,
      ...filters,
      ...mapSortUItoBE(SORT_FIELDS_MAP.deals, sorting),
    },
  });

  return data;
};

export const getAllDealsDownload = async ({ filters, sorting, rowCount }: RequestDownloadPayload) => {
  const { data, headers } = await getXLSXDownload("/deals", {
    ...filters,
    ...mapSortUItoBE(SORT_FIELDS_MAP.deals, sorting),
    row_count: rowCount,
  });

  return { data, headers };
};

export const requestDealsDownload = async ({ filters, sorting, rowCount }: RequestDownloadPayload) => {
  const { data } = await goldApi.get<{ id: string }>("/deals/download", {
    params: {
      ...filters,
      ...mapSortUItoBE(SORT_FIELDS_MAP.deals, sorting),
      row_count: rowCount,
    },
    headers: {
      ...getTimeZoneHeader(),
    },
  });

  return data;
};

// Chart API query params handlers
const getPeriod = (datasetType: DatasetTypes): DatasetTypesEnum => {
  switch (datasetType) {
    case "quarterYear":
      return DatasetTypesEnum.quarterYear;
    case "halfYear":
      return DatasetTypesEnum.halfYear;
    case "year":
      return DatasetTypesEnum.year;
    default:
      return DatasetTypesEnum.config;
  }
};

const getChartFiltersParams = ({
  datasetType,
  chartVariant,
  storeFilters,
  primarySectorId,
}: DealsChartServiceInput) => {
  const filters: Record<string, number | string | string[] | boolean> = {
    period: getPeriod(datasetType),
    group_by: chartVariant,
    ...storeFilters,
  };

  if (primarySectorId && !("company_primary_sector_id" in filters)) {
    filters.company_primary_sector_id = primarySectorId;
  }

  return filters;
};

const CHART_BASE_PARAMS = {
  page: 1,
  page_size: 0,
  deal_date_range_after: CHART_PAST_DATE_LIMIT,
};

export const getDealsChartsData = async (params: DealsChartServiceInput) => {
  const filters = getChartFiltersParams(params);

  const { data } = await goldApi.get<DealsChartResponse>("/deals/aggregate/bar", {
    params: {
      ...CHART_BASE_PARAMS,
      ...filters,
    },
  });

  return data;
};

export const getDealsChartsDataDownload = async (params: DealsChartServiceInput) => {
  const filters = getChartFiltersParams(params);
  const { data, headers } = await getXLSXDownload("/deals/aggregate/bar", {
    ...CHART_BASE_PARAMS,
    ...filters,
  });

  return { data, headers };
};

export const requestDealsChartsDataDownload = async (params: DealsChartServiceInput) => {
  const filters = getChartFiltersParams(params);
  const { data } = await goldApi.get<{ id: string }>("/deals/aggregate/bar/download", {
    params: {
      ...CHART_BASE_PARAMS,
      ...filters,
    },
  });

  return data;
};
