import { useEffect, useRef } from "react";

import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";

import { ROUTES, PUBLIC_LANDING_ROUTES } from "@Lib/constants";
import { type UserFE } from "@Lib/types/user";
import { showSuccessNotification } from "@Lib/utils/notifications";
import { isPublicRoute, getLandingRedirect } from "@Lib/utils/routes";

export const useHanldeAuthRouting = (localUser: UserFE, fbAuthChecked: boolean) => {
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  // const { trackLogin } = useUserTrackingContext();

  // We store the route a user used to access the app in order to serve it after login
  const landingPathnameRef = useRef(location.pathname);
  // Store the search params to preserve them after login
  const landingSearchRef = useRef(location.search);

  // Handle auth routing
  useEffect(() => {
    if (PUBLIC_LANDING_ROUTES.has(location.pathname)) {
      return;
    }

    // User data fetched and we are on a public route
    if (localUser && isPublicRoute(location.pathname)) {
      const goto = getLandingRedirect(landingPathnameRef.current, landingSearchRef.current);
      // Set to default we don't need it anymore
      landingPathnameRef.current = ROUTES.login;
      landingSearchRef.current = "";

      navigate(goto, { replace: true });

      showSuccessNotification({
        id: "login-success",
        message: t("notifications.login.success.message"),
      });
      return;
    }

    // Hanlde back navigation in case user is logged out
    if (fbAuthChecked && !localUser && !isPublicRoute(location.pathname)) {
      navigate(ROUTES.login, { replace: true });
      return;
    }
  }, [location.pathname, localUser]);
};
